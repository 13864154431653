:root {
  --brand-color: #4182e4;
  --secondary-brand-color: #4182e4;
}

body {
  margin: 0;
  font-family: "San Francisco", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

a {
  color: #00a4ca;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

.text-center {
  text-align: center;
}

.page-header {
  font-weight: 700;
  font-size: 2.2em;
  margin: 0 0 30px 0;
}

.subtext {
  font-size: 1.38em;
  color: #666;
  margin-bottom: 20px;
  margin-top: 0;
  font-weight: 300;
  line-height: 1.4em;
}

.faq {
  color: #666;
}

.faq div {
  break-inside: avoid;
  padding: 25px 0;
}

.faq dt {
  font-weight: bold;
  margin: 0 0 5px 0;
}

.faq dd {
  padding: 0;
  margin: 0;
}
