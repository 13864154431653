header {
  color: #fff;
  padding: 20px 0;
  background: var(--brand-color); /* Old browsers */
  background: linear-gradient(to bottom, var(--brand-color) 0%, var(--brand-color) 100%) no-repeat var(--brand-color);
}
header a {
  color: #fff;
  text-decoration: none;
  z-index: 1;
  position: relative;
}
header a:hover {
  text-decoration: none;
}
header .company-name {
  font-size: 1.7em;
  line-height: 0;
}
header .company-name a {
  display: inline-block;
  margin-right: 8px;
}
header .company-name img {
  display: block;
  width: auto;
}

nav {
  text-transform: uppercase;
  font-size: 0.8em;
  width: 100%;
  text-align: right;
  padding: 0 20px;
}
nav a {
  margin: 0 3px;
  padding: 20px 10px;
  border-bottom: 1px solid rgba(255, 255, 255, 0);
  color: rgba(255, 255, 255, .8);
  transition: 0.2s ease-in-out;
  display: none;
}
@media (min-width: 600px) {
  nav a {
    display: inline;
    padding: 10px;
  }
}
nav .nav-toggle {
  margin: 0 3px;
  padding: 20px 10px;
  color: rgba(255, 255, 255, .8);
  transition: 0.2s ease-in-out;
  display: inline;
  font-size: 1.9em;
  border: 0;
  cursor: pointer;
}
@media (min-width: 600px) {
  nav .nav-toggle {
    display: none;
  }
}
nav .nav-toggle:hover {
  border: 0;
}
nav a:hover {
  border-bottom: 1px solid rgba(255, 255, 255, .3);
  color: #fff;
}
@media (min-width: 600px) {
  nav a.highlight {
    border: 1px #ccc solid;
    border-radius: 5px;
  }
  nav a.highlight:hover {
    background: #fff;
    color: #4182e4;
  }
}
nav a.active {
  color: #fff;
}
