footer {
  padding: 50px 0 50px 0;
  font-size: 1.1em;
  position: relative;
  background: #2b2b40;
  color: #fff;
}

footer .copyright {
  color: white;
  font-size: 0.8em;
  margin: 0 auto;
}

footer, footer a {
  color: #999;
}

/*.footer-links {*/
/*  width: 100%;*/
/*  margin: 10px;*/
/*  padding: 0;*/
/*}*/

.footer-links li {
  list-style: none;
  margin: 15px auto;
}

footer h2 {
  font-size: 1.4em;
  margin-top: 0;
  color: #ccc;
}

footer .footer-columns {
  margin: -10px -10px 10px -10px;
}

footer a {
  text-decoration: none;
}

footer a:hover {
  color: #fff;
}

footer .legal-line {
  width: 100%;
  padding: 30px 0;
  margin: 0;
  background-color: #222527;
}

footer .legal-line a {
  font-weight: 600;
}

footer .item p {
  padding: 0;
  margin: 0;
  font-size: 0.9em;
  color: white;
}

footer .item img {
  width: 120px;
  text-align: center;
}

footer .item a {
  color: #19c6e4;
  text-decoration: none;
}

footer .item a:hover {
  color: #7253ed;
}

@media (min-width: 900px) {
  footer .address {
    text-align: right;
  }
}

footer .contact-details h2 {
  font-weight: bold;
  color: white;
}

footer .footer-container {
  border: 1px solid red;
  border-top: 1em solid red;
}
